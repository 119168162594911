// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import MerchantDashboard2 from "../../blocks/MerchantDashboard2/src/MerchantDashboard2";
import Maps from "../../blocks/maps/src/Maps";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import Settings from "../../blocks/Settings/src/Settings";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import AdminConsole2 from "../../blocks/AdminConsole2/src/AdminConsole2";
import CheckinModeApi from "../../blocks/CheckinModeApi/src/CheckinModeApi";
import CfAccountValidation from "../../blocks/CfAccountValidation/src/CfAccountValidation";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import Customform from "../../blocks/customform/src/Customform";
import Gallery from "../../blocks/Gallery/src/Gallery";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Notifications from "../../blocks/notifications/src/Notifications";
import CfZohoIntegration4 from "../../blocks/CfZohoIntegration4/src/CfZohoIntegration4";
import Addresses from "../../blocks/addressmanagement/src/Addresses";
import AddAddress from "../../blocks/addressmanagement/src/AddAddress";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import CfUrlCreation from "../../blocks/CfUrlCreation/src/CfUrlCreation";
import CfZoomIntegration35 from "../../blocks/CfZoomIntegration35/src/CfZoomIntegration35";
import Invitefriends from "../../blocks/invitefriends/src/Invitefriends";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import CfMicrosoftTeamsIntegration from "../../blocks/CfMicrosoftTeamsIntegration/src/CfMicrosoftTeamsIntegration";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Share from "../../blocks/share/src/Share";
import MatchAlgorithm from "../../blocks/MatchAlgorithm/src/MatchAlgorithm";
import ZipcodesSearch from "../../blocks/ZipcodesSearch/src/ZipcodesSearch";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Reservations from "../../blocks/Reservations/src/Reservations";
import Location from "../../blocks/location/src/Location";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import ProductRecommendationEngine from "../../blocks/ProductRecommendationEngine/src/ProductRecommendationEngine";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import NearbyFriends from "../../blocks/NearbyFriends/src/NearbyFriends";
import CfApiIntegration15 from "../../blocks/CfApiIntegration15/src/CfApiIntegration15";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import CfSlackIntegration3 from "../../blocks/CfSlackIntegration3/src/CfSlackIntegration3";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import CfServiceNowIntegration2 from "../../blocks/CfServiceNowIntegration2/src/CfServiceNowIntegration2";
import CommunityForum from "../../blocks/CommunityForum/src/CommunityForum";



const routeMap = {
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
MerchantDashboard2:{
 component:MerchantDashboard2,
path:"/MerchantDashboard2"},
Maps:{
 component:Maps,
path:"/Maps"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
Settings:{
 component:Settings,
path:"/Settings"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
AdminConsole2:{
 component:AdminConsole2,
path:"/AdminConsole2"},
CheckinModeApi:{
 component:CheckinModeApi,
path:"/CheckinModeApi"},
CfAccountValidation:{
 component:CfAccountValidation,
path:"/CfAccountValidation"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
Customform:{
 component:Customform,
path:"/Customform"},
Gallery:{
 component:Gallery,
path:"/Gallery"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
CfZohoIntegration4:{
 component:CfZohoIntegration4,
path:"/CfZohoIntegration4"},
Addresses:{
 component:Addresses,
path:"/Addresses"},
AddAddress:{
 component:AddAddress,
path:"/AddAddress"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
CfUrlCreation:{
 component:CfUrlCreation,
path:"/CfUrlCreation"},
CfZoomIntegration35:{
 component:CfZoomIntegration35,
path:"/CfZoomIntegration35"},
Invitefriends:{
 component:Invitefriends,
path:"/Invitefriends"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
CfMicrosoftTeamsIntegration:{
 component:CfMicrosoftTeamsIntegration,
path:"/CfMicrosoftTeamsIntegration"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Share:{
 component:Share,
path:"/Share"},
MatchAlgorithm:{
 component:MatchAlgorithm,
path:"/MatchAlgorithm"},
ZipcodesSearch:{
 component:ZipcodesSearch,
path:"/ZipcodesSearch"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
RolesPermissions:{
 component:RolesPermissions,
path:"/RolesPermissions"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Reservations:{
 component:Reservations,
path:"/Reservations"},
Location:{
 component:Location,
path:"/Location"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
ProductRecommendationEngine:{
 component:ProductRecommendationEngine,
path:"/ProductRecommendationEngine"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
NearbyFriends:{
 component:NearbyFriends,
path:"/NearbyFriends"},
CfApiIntegration15:{
 component:CfApiIntegration15,
path:"/CfApiIntegration15"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
EmailNotifications2:{
 component:EmailNotifications2,
path:"/EmailNotifications2"},
CfSlackIntegration3:{
 component:CfSlackIntegration3,
path:"/CfSlackIntegration3"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
CfServiceNowIntegration2:{
 component:CfServiceNowIntegration2,
path:"/CfServiceNowIntegration2"},
CommunityForum:{
 component:CommunityForum,
path:"/CommunityForum"},

  Home: {
component:Splashscreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
